<template>
  <div class="">
    <div class="sm:flex sm:flex-col">
      <div class="mt-3 text-center sm:mt-0 sm:text-left">
        <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
          {{ action }} {{ newButton.label }}
        </h3>
        <div class="mt-2">
          <label for="email" class="block text-sm font-medium text-gray-700">Name</label>
          <div class="mt-1">
            <input
              type="text"
              v-model="name"
              class="border px-2 py-1 block w-full sm:text-sm border-gray-300 rounded"
              placeholder="Organization Name"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <button
        type="button"
        class="w-full inline-flex justify-center rounded border border-transparent px-3 py-1 bg-indigo-600 font-medium text-white hover:bg-indigo-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-xs"
        @click.prevent="update"
      >
        Save
      </button>
      <button
        type="button"
        class="mt-3 w-full inline-flex justify-center rounded border border-gray-300 px-3 py-1 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:w-auto sm:text-xs"
        @click.prevent="setModal({ show: false })"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import http from '@/utils/http'

export default {
  name: 'NewInstance',
  props: [],
  data() {
    return {
      name: null,
    }
  },
  methods: {
    update() {
      http
        .post(`/instances`, { name: this.name })
        .then(() => {
          this.$bus.$emit(this.modal.notifier)
          this.setModal({ show: false })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
  computed: {
    action() {
      return this.modal.payload ? 'Update' : 'Create'
    },
  },
}
</script>

<style></style>
